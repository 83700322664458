import { createRouter as createVueRouter, createWebHistory } from 'vue-router';
import { defineAsyncComponent } from 'vue';
import store from '../store';
import { beforeEach as beforeEachDefault, fastLoadFromParams } from './default-router';

var router, currentH = {}, isPush = false, isReplace = false, isGo = false;
function beforeEachReturn() {
    isPush = false;
    isReplace = false;

    return;
}

function getComponent(to) {
    let defaultComponent = to.matched[0].components.default;
    // импортируем компонент из роута. Если он еще не инициализирован, то определится как функция
    return typeof defaultComponent === 'function' ? defineAsyncComponent(defaultComponent) : defaultComponent;
}

/**
 * Меняем состояния истории браузера
 */
function pushReplaceHistory(to, from) {
    let newState = {
        back: currentH.state ? currentH.state.current : from.fullPath,
        current: to.fullPath,
    };
    if (window.history.state) {
        newState = Object.assign({}, window.history.state, newState);
    }

    if (isPush) {
        // if (!currentH.state) {
        //     let backState = {
        //         forward: to.fullPath,
        //         position: window.history.length - 1,
        //         replaced: true,
        //     };
        //     window.history.replaceState(Object.assign({}, window.history.state, backState), '', to.fullPath);
        // }

        newState.position = newState.position ? newState.position + 1 : window.history.length + 0;
        newState.replaced = false;
        window.history.pushState(newState, '', to.fullPath);
        currentH.state = newState;
        currentH.to = to;
    } else if (isReplace) {
        newState.position = newState.position ? newState.position : window.history.length - 1;
        newState.replaced = true;
        window.history.replaceState(newState, '', to.fullPath);
        currentH.state = newState;
        currentH.to = to;
    } else {
        currentH = {};
    }
}

export function beforeEach(to, from, next) {
    // let fullPath = window.location.pathname + window.location.search + window.location.hash;
    let path = window.location.pathname;

    // Если перешли по ссылке на основную немодальную страницу, то закрываем все модальные окна с учетом истории
    if (from.name === to.name && to.path !== path && store.getters.modalActiveIndex !== null) {
        // @todo: прыжок идет только с учетом модальности, а надо еще учитывать историю переходов внутри компонента типа по хешам
        router.go(- store.getters.modalActiveIndex - 1);
        store.commit('setModal');
        store.commit('reloadPage');
        return beforeEachReturn();
    }

    // Если это не стартовая страница которую мы отрыли и она является модальным окном, которое открыто именно модально
    if (from.name && (from.path !== to.path) && to.meta.isModal && (!isReplace || currentH.state)) {
        let modals = store.getters.modals;

        for (let i in modals) {
            if (modals[i].currentH.to && modals[i].currentH.to.path === to.path) {
                if (to.path !== path) { // если перешли не по тому же пути
                    modals[i].currentH.to = to;
                    modals[i].currentH.state.current = to.fullPath;
                    modals[i].currentH.state.replaced = true;
                    isGo = true;

                    router.go(store.getters.modalActiveIndex === null ? parseInt(i) + 1 : i - store.getters.modalActiveIndex);
                    return; // просто return, т.к. go вызовет еще один переход
                }

                if (isGo) {
                    // это нужно для срабатывания после window.history.go, т.к. полный путь мб с хешем #
                    window.history.replaceState(JSON.parse(JSON.stringify(modals[i].currentH.state)), '', modals[i].currentH.to.fullPath);
                    isGo = false;
                } else if ((isPush || isReplace) && currentH.to && currentH.to.fullPath !== to.fullPath) { // только если разный полный путь - пишем историю
                    // @todo: при переходе с хэшем с той же страницы, хэш не подгрудается с $this.router - разобраться
                    pushReplaceHistory(to, from);
                    modals[i].currentH = Object.assign({}, currentH);
                }

                // доб. параметры пишем даже если одинаковый url, чтобы работало при forward и replace
                fastLoadFromParams(modals[i].currentH.to, from);

                if ((isPush || isReplace) && currentH.to && currentH.to.path !== to.path) { // только при прямом переходе, а не возврате. И только с другой страницы
                    // перезаписываем и тем самым перезагружаем компонент
                    modals[i].component = getComponent(modals[i].currentH.to);
                }
                // учитываем историю
                currentH = Object.assign({}, modals[i].currentH);
                // переходим к модальному окну по текущий индекс
                store.commit('setModal', i);
                // если найдено окно, то завершаем
                return beforeEachReturn();
            }
        }
        pushReplaceHistory(to, from);

        fastLoadFromParams(to, from);

        // добавляем/открываем модальное окно
        let modal = { component: getComponent(to), isOpen: true, size: to.meta.modalSize, currentH: Object.assign({}, currentH) };
        isReplace ? store.commit('replaceModal', modal) : store.commit('pushModal', modal);

        beforeEachReturn();
    } else {
        if (from.path === to.path) {
            // Закрываем все модальные окна, если перешли на основную немодальную страницу
            store.commit('setModal');
        } else {
            // Удаляем все модальные окна, если перешли на совсем другую немодальную страниц
            store.commit('delModals');
        }

        // сохраняем предыдущее состояние, чтобы верно проверять при перезагрузке компонент
        if (currentH.to) {
            from = currentH.to;
        }
        currentH = {};

        beforeEachDefault(to, from, next);

        beforeEachReturn();
    }
};

export function createRouter(options) {
    options.history = createWebHistory();
    router = createVueRouter(options);
    let routerTemp = Object.assign({}, router);

    router.beforeEach(beforeEach);

    router.push = function (to, data) {
        isPush = true;

        return routerTemp.push(to, data);
    }
    router.replace = function (to, data) {
        isReplace = true;

        return routerTemp.replace(to, data);
    }

    return router;
}
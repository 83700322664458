<template>
  <div>
    <Galleria
      :value="serviceF"
      v-model:activeIndex="activeIndex"
      :fullScreen="true"
      :showItemNavigators="true"
      :showThumbnails="showThumbnails"
      v-model:visible="displayCustom"
      :numVisible="10"
    >
      <template #item="{ item }">
        <img v-if="item.isImage" :src="item.url" :alt="item.origname" />
        <span v-else class="text-center d-block">
          <i :class="'fa-3x ' + getFileIconClass(item.extension)"></i> <br />
          {{ item.origname }}
        </span>
      </template>
      <template #thumbnail="{ item }">
        <img :src="item.urlMin || item.url" :alt="item.alt" style="max-height: 50px" />
      </template>
    </Galleria>

    <!-- @todo: оптимизировать -->
    <div v-if="serviceF">
      <template v-if="mode === 'scroll'">
        <div class="d-flex flex-nowrap overflow-auto">
          <div
            v-for="(item, index) of Object.prototype.toString.call(serviceF) === '[object Array]'
              ? serviceF
              : [serviceF]"
            :key="index"
            class=""
          >
            <div class="position-relative img-thumbnail img-scroll-thumbnail">
              <a
                style="display: block; height: 100px; overflow-x: hidden; cursor: pointer"
                class="text-center"
                target="_blank"
                :href="item.url"
                @click="item.isImage ? onClickFile($event, index) : () => {}"
              >
                <img
                  v-if="item.isImage"
                  :src="item.urlMin || item.url"
                  :alt="item.alt"
                  style="max-height: 100%; position: relative; top: 50%; transform: translateY(-50%)"
                />
                <span
                  v-else
                  style="display: block; max-height: 100%; position: relative; top: 50%; transform: translateY(-50%)"
                >
                  <i :class="'fa-3x ' + getFileIconClass(item.extension)"></i> <br />
                  <small style="line-height: 1px">{{ item.origname }}</small>
                </span>
              </a>
              <Button
                v-if="edit"
                @click="onDeleteFile(index)"
                icon="fa-solid fa-trash-can"
                class="p-button-rounded p-button-danger position-absolute img-thumbnail-hover"
                style="width: 2rem; height: 2rem; top: -10px; right: -10px"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="mode === 'view'">
        <div class="d-flex flex-wrap" :class="{ 'justify-content-end': position == 'right' }">
          <div
            v-for="(item, index) of Object.prototype.toString.call(serviceF) === '[object Array]'
              ? serviceF
              : [serviceF]"
            :key="index"
            
          >
            <!-- class="col-12 md:col-2 sm:col-6 mb-2" -->
            <div class="position-relative">
              <Button
                v-if="edit"
                @click="onDeleteFile(index)"
                icon="fa-solid fa-trash-can"
                class="p-button-rounded p-button-danger position-absolute img-thumbnail-hover"
                style="width: 2rem; height: 2rem; top: -10px; right: -10px"
              />
              <a
                style="display: block; height: 100%; overflow-x: hidden; cursor: pointer"
                class="text-center"
                target="_blank"
                :href="item.url"
                @click="item.isImage ? onClickFile($event, index) : () => {}"
              >
                <img
                  v-if="item.isImage"
                  :src="item.urlMin || item.url"
                  :alt="item.alt"
                  style="max-height: 100%; position: relative; top: 50%;"
                  class="img-fluid"
                />
                <span
                  v-else
                  style="display: block; max-height: 100%; position: relative; top: 50%;"
                >
                  <i :class="'fa-3x ' + getFileIconClass(item.extension)"></i> <br />
                  <small style="line-height: 1px">{{ item.origname }}</small>
                </span>
              </a>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="mode === 'list'">
        <div class="grid">
          <!-- <Accordion>
          <AccordionTab header="Показать все файлы"> -->
          <div
            v-for="(item, index) of Object.prototype.toString.call(serviceF) === '[object Array]'
              ? serviceF
              : [serviceF]"
            :key="index"
            class="col-12 py-0"
          >
            <div class="d-flex align-items-center">
              <a target="_blank" :href="item.url" @click="item.isImage ? onClickFile($event, index) : () => {}">
                <span
                  ><i :class="item.isImage ? 'fa-regular fa-file-image' : getFileIconClass(item.extension)"></i
                ></span>
                {{ item.origname + '.' + item.extension }}
              </a>
              <Button
                v-if="edit"
                @click="onDeleteFile(index)"
                icon="fa-solid fa-trash-can"
                class="p-button-rounded p-button-text p-button-secondary"
                style="width: 2rem; height: 2rem"
              />
            </div>
          </div>
          <!-- </AccordionTab>
        </Accordion> -->
        </div>
      </template>
      <template v-else>
        <div class="grid">
          <div
            v-for="(item, index) of Object.prototype.toString.call(serviceF) === '[object Array]'
              ? serviceF
              : [serviceF]"
            :class="adaptiveClass"
            :style="adaptiveStyle"
            :key="index"
          >
            <div class="position-relative img-thumbnail">
              <Button
                v-if="edit"
                @click="onDeleteFile(index)"
                icon="fa-solid fa-trash-can"
                class="p-button-rounded p-button-danger position-absolute img-thumbnail-hover"
                style="width: 2rem; height: 2rem; top: -10px; right: -10px"
              />
              <!-- Здесь специально не используются flex, т.к. задумано, что изображение ставится по высоте на 100%, центрируется. Но если по ширине не входит, то показывается от левого края -->
              <!-- Если нужно будет использовать полное центрирование, то использовать d-flex align-items-center justify-content-center -->
              <a
                style="display: block; height: 100px; overflow-x: hidden; cursor: pointer"
                class="text-center"
                target="_blank"
                :href="item.url"
                @click="item.isImage ? onClickFile($event, index) : () => {}"
              >
                <img
                  v-if="item.isImage"
                  :src="item.urlMin || item.url"
                  :alt="item.alt"
                  style="max-height: 100%; position: relative; top: 50%; transform: translateY(-50%)"
                />
                <span
                  v-else
                  style="display: block; max-height: 100%; position: relative; top: 50%; transform: translateY(-50%)"
                >
                  <i :class="'fa-3x ' + getFileIconClass(item.extension)"></i> <br />
                  <small style="line-height: 1px">{{ item.origname }}</small>
                </span>
              </a>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Galleria from 'primevue/galleria';
import http from '@/utils/http-common';

export default {
  components: { Accordion, AccordionTab, Button, Galleria },
  props: {
    service: Object,
    field: String,
    edit: Boolean,
    showThumbnails: Boolean,
    mode: String,
    adaptiveClass: {
      type: String,
      default: 'col-6 sm:col-4 md:col-2',
    },
    adaptiveStyle: {
      type: String,
      default: 'margin-bottom: 20px',
    },
    url: String,
    position: String,
  },
  data() {
    return {
      activeIndex: 0,
      displayCustom: false,
    };
  },
  computed: {
    serviceF: {
      get() {
        return this.$obj.getField(this.service, this.field);
      },
      set(v) {
        this.$obj.setField(this.service, this.field, v);
      },
    },
  },
  methods: {
    async onDeleteFile(index) {
      await this.$confirm.require({
        message: this.$t('Are you sure you want to proceed?'),
        header: this.$t('Confirmation'),
        icon: 'fa-solid fa-triangle-exclamation',
        accept: async () => {
          let id;
          if (Object.prototype.toString.call(this.serviceF) === '[object Array]') {
            id = this.serviceF[index].id;

            this.serviceF.splice(index, 1);
          } else {
            id = this.serviceF.id;

            this.serviceF = null;
          }

          let url = this.url ? this.url : '/' + this.service.basePath + '/upload';

          await http.delete(url + '-delete/' + id);
        },
      });
    },
    onClickFile(e, index) {
      this.activeIndex = index;
      this.displayCustom = true;

      e.preventDefault();
    },
    getFileIconClass(extension) {
      if (extension === 'pdf') {
        return 'fa-regular fa-file-pdf';
      } else if (['txt', 'rtf', 'odt', 'pages', 'numbers'].indexOf(extension) !== -1) {
        return 'fa-regular fa-file-lines';
      } else if (['doc', 'docx'].indexOf(extension) !== -1) {
        return 'fa-regular fa-file-word';
      } else if (['xls', 'xlsx'].indexOf(extension) !== -1) {
        return 'fa-regular fa-file-excel';
      } else if (['ptt', 'pttx'].indexOf(extension) !== -1) {
        return 'fa-regular fa-file-powerpoint';
      } else if (extension === 'csv') {
        return 'fa-solid fa-file-csv';
      } else if (['json', 'xml', 'key'].indexOf(extension) !== -1) {
        return 'fa-regular fa-file-code';
      } else if (['zip', 'rar'].indexOf(extension) !== -1) {
        return 'fa-regular fa-file-zipper';
      }

      return 'fa-regular fa-file';
    },
  },
};
</script>

<style scoped>
.img-thumbnail-hover {
  display: none;
}
.img-thumbnail.img-scroll-thumbnail {
  margin: 0 5px;
  padding: inherit;
  background-color: inherit;
  border: none;
}
.img-thumbnail.img-scroll-thumbnail img {
  border-radius: 10px;
}
.img-thumbnail:hover .img-thumbnail-hover {
  display: flex;
  z-index: 1;
}
</style>